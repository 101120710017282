import { Log } from './Log';
import { IApproximateWaitingTimeResponse } from '../Common/interfaces';

export class HeartbeatRequest {
    public RequestWaitingTime = false;
    public RequestWaitingroomMessages = false;
}

export interface HeartbeatResponse {
    Message?: string;
    WaitingTime?: IApproximateWaitingTimeResponse;
    Duration?: number;
    BitRate?: number;
    Failed?: boolean;
}

export class Heartbeat {
    public static isStarted = false;
    public static isFetching = false;
    public static heartbeatRequest: HeartbeatRequest = new HeartbeatRequest();
    public static start(
        videoSessionId: number,
        heartbeatRequest: HeartbeatRequest,
        onHeartBeatResponseCallBack: (response: HeartbeatResponse) => void,
    ): void {
        Heartbeat.heartbeatRequest = heartbeatRequest;
        if (this.isStarted) {
            Log.warn('Heartbeat already started', { videoSessionId });
            return;
        }
        this.isStarted = true;
        Heartbeat.loop(
            videoSessionId,
            onHeartBeatResponseCallBack,
            Heartbeat.heartbeatRequest,
        );
        setInterval(
            () =>
                Heartbeat.loop(
                    videoSessionId,
                    onHeartBeatResponseCallBack,
                    Heartbeat.heartbeatRequest,
                ),
            3000,
        );
    }

    private static loop(
        videoSessionId: number,
        onHeartBeatResponseCallBack: (data: any) => void,
        heartbeatRequest: HeartbeatRequest,
    ): void {
        if (this.isFetching) {
            return;
        }
        this.isFetching = true;

        performance.mark('hb-start');
        fetch('/api/consultations/heartbeat/' + videoSessionId, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(heartbeatRequest),
        })
            .then(async (response) => {
                let hbr: HeartbeatResponse = {};
                let nbytes = 0;
                if (response.ok) {
                    hbr = await response.json();
                    nbytes = Number.parseInt(
                        response.headers.get('Content-length'),
                    );
                } else {
                    hbr.Failed = true;
                }

                // Get the duratin for the fetch call
                hbr.Duration = performance.measure(
                    'hb-start-to-end',
                    'hb-start',
                ).duration;

                // Poor man's bit rate calculation. However, it is a number which depends
                // on body size of the response body and is therefore more relaible then
                // Duration. In case of an request error, response body size will be zero
                // and bit rate will be set to zero as well.
                hbr.BitRate = Math.floor(
                    (nbytes * 8) / (hbr.Duration / 1000.0),
                );

                // Clear marks and measurments
                performance.clearMarks('hb-start');
                performance.clearMeasures('hb-start-to-end');

                return Promise.resolve(hbr);
            })
            .then(onHeartBeatResponseCallBack)
            .catch((exception) => {
                //Swallowing any exception for heartbeat because they are not actionable.
            })
            .finally(() => {
                this.isFetching = false;
            });
    }
}
